import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import ptTranslation from './locales/pt.json';
import arTranslation from './locales/ar.json';


function getLanguageFromURL() {
  const params = new URLSearchParams(window.location.search);
  return params.get('lang') || 'en';
}

const resources = {
  en: {
    translation: enTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  ar: {
    translation: arTranslation,
  },
};

const defaultLanguage = 'en';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getLanguageFromURL() || defaultLanguage,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
