import React from 'react';
import LoginPage from './pages/Login';
import './styles/tailwind.css'
import RegisterForm from './pages/Register';
import VerificationCode from './pages/VerificationCode';
import ResendCode from './pages/ResendCode';
import ForgetPassword from './pages/ForgetPassword';
import { NavLink, Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import ResetPassword from './pages/ResetPassword';

const App: React.FC = () => {
  return (
    <div className='container'>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/verificationCode" element={<VerificationCode />} />
        <Route path="/resendCode" element={<ResendCode />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
};

export default App;
