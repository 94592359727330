import React from "react";
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const store = queryParams.get('store');

  const sacoorBrothersText = `${t("SacoorBrothers")}`;
  const sacoorBrothersBlue = `${t("SacoorBlue")}`;
  const SacoorOne = `${t("SacoorOne")}`;

  const getStoreLogo = (storeDomain: string | null) => {
    if (storeDomain && storeDomain.includes('sacoorone')) {
      return SacoorOne;
    } else if (storeDomain && storeDomain.includes('sacoorblue')) {
      return sacoorBrothersBlue;
    } else {
      return sacoorBrothersText;
    }
  };

  return (
    <div className="footer">
      <div className="full text-center pt-6 pb-6 footerBottom">
        <p className="text-center">© 2024 {getStoreLogo(store)}.</p>
      </div>
    </div>
  );
};

export default Footer;
