import React, { useEffect, useState } from "react";
import { countryList } from "../../api/countrylist";
import { register } from "../../api/api";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useTranslation } from "react-i18next";

interface FormState {
  firstName: string;
  lastName: string;
  countryCode: string;
  phone: string;
  email: string;
  birthday: string;
  gender: string;
  password: string;
  confirmPassword: string;
  isPrivacyPolicyAgree: boolean,
  acceptsMarketing: boolean,
}

const RegisterForm: React.FC = () => {
  const { t } = useTranslation();

  const getInitialLanguage = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('lang') || 'en';
  };

  const [selectedLanguage, setSelectedLanguage] = useState<string>(getInitialLanguage);

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  const [formData, setFormData] = useState<FormState>({
    firstName: "",
    lastName: "",
    countryCode: "",
    phone: "",
    email: "",
    birthday: "",
    gender: "",
    password: "",
    confirmPassword: "",
    isPrivacyPolicyAgree: false,
    acceptsMarketing: false,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const store = queryParams.get('store');
  const checkout_url_param = queryParams.get('checkout_url');

  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [birthdayError, setBirthdayError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickTc = () => {
//    window.open(`https://${store}/pages/terms-and-conditions-of-online-shopping&lang=${selectedLanguage}`, "_blank")
    window.open(`https://${store}/pages/terms-and-conditions-of-online-shopping`, "_blank")
  };

  const handleClickPp = () => {
//    window.open(`https://${store}/pages/privacy-policy&lang=${selectedLanguage}`, "_blank")
    window.open(`https://${store}/pages/privacy-policy`, "_blank")
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "phone") {
      if (!validatePhoneNumber(value)) {
        setPhoneError(`${t("invalidPhone")}`);
      } else {
        setPhoneError("");
      }
    }

    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailError(`${t("invalidEmail")}`);
      } else {
        setEmailError("");
      }
    }

    if (name === "birthday") {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      if (selectedDate > currentDate) {
        setBirthdayError(`${t("validDate")}`);
      } else {
        setBirthdayError("");
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const navigate = useNavigate();

  const validatePhoneNumber = (phoneNumber: string): boolean => {
    const phoneRegex = /^\d{5,15}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleCalendarClick = () => {
    document.getElementById("birthday")?.focus();
  };

  useEffect(() => {
    if (!store) {
      window.location.href = "https://www.sacoorbrothers.com";
    }
  }, [store]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);

    if (!validateEmail(formData.email)) {
      setEmailError(`${t("invalidEmail")}`);
      return;
    }

    if (!validatePhoneNumber(formData.phone)) {
      setPhoneError(`${t("invalidPhone")}`);
      return;
    }

    const response = await register(formData, selectedLanguage);      
      if (response.meta.statusCode !== 200 && response.meta.statusCode !== 201) {
        setLoading(false);
        setError(response.meta.message)
        console.log("Status code", response.meta.statusCode)
      } else {
        setSuccessMessage(response.meta.message);
        setLoading(false);
        setTimeout(() => {
          checkout_url_param ? navigate(`/verificationCode?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`) : 
          navigate(`/verificationCode?store=${store}&lang=${selectedLanguage}`)
          localStorage.setItem('registeredEmail', formData.email);
        }, 3000)
      }
  };

  return (
    <>
    <Header selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} /> 
      <div className="registerForm">
        <h2 className="mb-5 text-3xl text-center">{t("Register")}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="firstName">{t("firstName")}</label>
            <input
              className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="lastName">{t("lastName")}</label>
            <input
              className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="countryCode">{t("countryCode")}</label>
            <select
              className="border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              id="countryCode"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              required
            >
              <option value="">{t("selectCountry")}</option>

              {countryList.map((country) => (
                <option key={country.code} value={country.dial_code}>
                  {country.name} ({country.dial_code})
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="phone">{t("phone")}</label>
            <input
              className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {phoneError && <div className="text-red">{phoneError}</div>}
          </div>
          <div>
            <label htmlFor="email">{t("email")}</label>
            <input
              className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {emailError && <div className="text-red">{emailError}</div>}
          </div>
          <div>
            <label htmlFor="birthday">{t("birthday")}</label>
            <input
              className="border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              type="date"
              id="birthday"
              name="birthday"
              value={formData.birthday}
              onChange={handleChange}
              onFocus={handleCalendarClick}
              placeholder="YYYY-MM-DD"
              max={new Date().toISOString().split('T')[0]}
            />
            {birthdayError && <div className="text-red">{birthdayError}</div>}
          </div>
          <div>
            <label htmlFor="gender">{t("gender")}</label>
            <select
              className="border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <option value="">{t("selectGender")}</option>
              <option value="Male">{t("male")}</option>
              <option value="Female">{t("female")}</option>
              <option value="Prefer not to mention">{t("prefer")}</option>
            </select>
          </div>
          <div className="passwordFiled">
            <label htmlFor="password">{t("password")}</label>
            <input
              className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {showPassword ? (
            <FaEyeSlash onClick={handlePasswordToggle} />
            ) : (
              <FaEye onClick={handlePasswordToggle} />
            )}
          </div>
          <div className="passwordFiled">
            <label htmlFor="confirmPassword">{t("confirmPassword")}</label>
            <input
              className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            {showConfirmPassword ? (
              <FaEyeSlash onClick={handleConfirmPasswordToggle} />
              ) : (
                <FaEye onClick={handleConfirmPasswordToggle} />
              )}
          </div>
          <div className="mb-5 contentPara">
              <input
                className="mr-2"
                type="checkbox"
                name="isPrivacyPolicyAgree"
                checked={formData.isPrivacyPolicyAgree}
                onChange={handleCheckboxChange}
                required
              />
              {t("agreeContent")} <span onClick={handleClickTc} className="linkColor mr-5">{t("tccondition")}</span> {t("clubText")} <span onClick={handleClickPp} className="linkColor mr-5">{t("privacyPolicy")}</span> {t("dataTreatment")}
          </div>
          <div className="contentPara">
            <label>
              <input
                className="mr-2"
                type="checkbox"
                name="acceptsMarketing"
                checked={formData.acceptsMarketing}
                onChange={handleCheckboxChange}
              />
              {t("notificationText")}
            </label>
          </div>
          {error && <div className="errorMsg text-red mt-4">{error}</div>}
          {successMessage && <div className="success-message">{successMessage}</div>}
          {loading && (
            <div className="loading">
              <div className="loadingWrapper">
                <div id="loading"> </div>
                <span className='loadMessage'>{t("loader")}</span>
              </div>
            </div>
          )}

        <button
          disabled={!!successMessage}
          className={`bg-black hover:bg-white/2 text-white font-normal mt-5 py-3 px-10 rounded ${
            successMessage ? 'cursor-not-allowed opacity-50' : ''
          }`}
          type="submit"
        >
          {t("Register")}
        </button>
          <p className="mt-4">
            {t("alreadyAccount")}
            {checkout_url_param ?
              <NavLink to={`/?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`} className="ml-3 linkColor"
              onClick={() => localStorage.removeItem('registeredEmail')}
              >
                {t("loginText")}
              </NavLink>
                :
                <NavLink to={`/?store=${store}&lang=${selectedLanguage}`} className="ml-3 linkColor"
                onClick={() => localStorage.removeItem('registeredEmail')}>
                {t("loginText")}
              </NavLink>
              }
          </p>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default RegisterForm;
