import React, { useEffect, useState } from "react";
import { resetPassword } from "../../api/api";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  const getInitialLanguage = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('lang') || 'en';
  };

  const [selectedLanguage, setSelectedLanguage] = useState<string>(getInitialLanguage);

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    verificationCode: "",
    domain: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  localStorage.getItem("email");

  const queryParams = new URLSearchParams(window.location.search);
  const store = queryParams.get("store");
  const checkout_url_param = queryParams.get('checkout_url');

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: storedEmail,
      }));
    }
  }, []);

  const navigate = useNavigate();

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!store) {
      window.location.href = "https://www.sacoorbrothers.com";
    }
  }, [store]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const response = await resetPassword({
      ...formData,
      domain: store,
    }, selectedLanguage);

    if (response.meta.statusCode !== 200 && response.meta.statusCode !== 201) {
      setLoading(false);
      setError(response.meta.message);
      console.log("Status code", response.meta.statusCode);
    } else {
      setSuccessMessage(response.meta.message);
      setLoading(false);
      setTimeout(() => {
        localStorage.removeItem("email");
        checkout_url_param ? navigate(`/?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`) : 
        navigate(`/?store=${store}&lang=${selectedLanguage}`);
      }, 3000);
    }
  };

  return (
    <>
    <Header selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} /> 
      <div className="content">
        <div className="loginForm">
          <h2 className="mb-5 text-3xl text-center">{t("ResetPassword")}</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label className="mb-3" htmlFor="email">
                {t("email")}
              </label>
              <input
                className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="passwordFiled">
              <label className="mb-3" htmlFor="email">
                {t("Password")}
              </label>
              <input
                className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {showPassword ? (
                <FaEyeSlash onClick={handlePasswordToggle} />
              ) : (
                <FaEye onClick={handlePasswordToggle} />
              )}
            </div>
            <div className="passwordFiled">
              <label className="mb-3" htmlFor="email">
                {t("ConfirmPassword")}
              </label>
              <input
                className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmpassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              {showConfirmPassword ? (
                <FaEyeSlash onClick={handleConfirmPasswordToggle} />
              ) : (
                <FaEye onClick={handleConfirmPasswordToggle} />
              )}
            </div>
            <div className="forgetSection">
              <label className="mb-3" htmlFor="verificationCode">
                {t("VerificationCode")}
              </label>
              <input
                className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
                type="text"
                id="number"
                name="verificationCode"
                value={formData.verificationCode}
                onChange={handleChange}
                required
              />
            </div>
            {error && <div className="text-red">{error}</div>}
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {loading && (
              <div className="loading">
                <div className="loadingWrapper">
                  <div id="loading"> </div>
                  <span className="loadMessage">{t("loader")}</span>
                </div>
              </div>
            )}
            <div className="full">
              <button
                disabled={!!successMessage}
                className={`bg-black hover:bg-white/2 text-white font-normal mt-5 py-3 px-10 rounded ${
                  successMessage ? "cursor-not-allowed opacity-50" : ""
                }`}
                type="submit"
              >
                {t("Verification")}
              </button>
            </div>

            <div className="linkRight">
            {checkout_url_param ?
              <NavLink to={`/?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`}>
              {t("login")}
            </NavLink>
            :
            <NavLink to={`/?store=${store}&lang=${selectedLanguage}`}>
              {t("login")}
            </NavLink>
            }
            {checkout_url_param ? <NavLink to={`/register?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`}>
                {t("Register")}
              </NavLink> : 
              <NavLink to={`/register?store=${store}&lang=${selectedLanguage}`}>
              {t("Register")}
            </NavLink>
              }
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
