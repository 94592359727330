import React, { useEffect, useState } from "react";
import { login, RefreshToken } from "../../api/api";
import { NavLink } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";

const LoginForm: React.FC = () => {
  const { t } = useTranslation();

  const getInitialLanguage = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("lang") || "en";
  };

  const [selectedLanguage, setSelectedLanguage] = useState<string>(getInitialLanguage());

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  const [email, setEmail] = useState("");
  const [userUUID, setUserUUID] = useState("");
  const [password, setPassword] = useState("");
  const [checkout_url, setCheckout_url] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const store = queryParams.get("store");
  const checkout_url_param = queryParams.get('checkout_url');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
    setEmailError("");
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("registeredEmail");
    const logoutAction = queryParams.get("action");
    if (logoutAction === "logout") {
      localStorage.clear();
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [queryParams]);

  useEffect(() => {
    const responseToken = async () => {
      try {
        const response = await RefreshToken(userUUID, checkout_url, selectedLanguage);
        console.log("api load", response);
        localStorage.setItem("userUUID", response.cognitoUserId);
        window.location.href = response.data.redirectUrl;
      } catch (error: any) {
        setLoading(false);
        setError(error.message);
      }
    };

    if (
      localStorage.getItem("cognitoUserId") ||
      localStorage.getItem("refreshToken")
    ) {
      setLoading(true);
      responseToken();
    }
  }, []);

  useEffect(() => {
    if (!store) {
      window.location.href = "https://www.sacoorbrothers.com";
    }
  }, [store]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError(`${t("invalidEmail")}`);
      return;
    }
    setLoading(true);
    try {
      const { data } = await login(email, password, checkout_url, selectedLanguage);
      localStorage.setItem("redirectUrl", data.redirectUrl);
      localStorage.setItem("cognitoUserId", data.cognitoUserId.Value);
      localStorage.setItem("TokenType", data.TokenType);
      localStorage.setItem("RefreshToken", data.RefreshToken);
      localStorage.setItem("AccessToken", data.AccessToken);
      localStorage.removeItem("registeredEmail");
      window.location.href = `${data.redirectUrl}?store=${store}&lang=${selectedLanguage}`;      
    } catch (error) {
      setLoading(false);
      setError(`${t("incorrectusername")}`);
    }
  };

  return (
    <>
      <Header
        selectedLanguage={selectedLanguage}
        handleLanguageChange={handleLanguageChange}
      />
      <div className="content">
        <div className="loginForm">
          <h2 className="mb-5 text-3xl text-center">{t("login")}</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label className="mb-3" htmlFor="email">
                {t("email")}
              </label>
              <input
                className={`appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline ${
                  emailError ? "border-red" : ""
                }`}
                type="email"
                id="email"
                value={email}
                onChange={handleChange}
                required
              />
              {emailError && <div className="text-red">{emailError}</div>}
            </div>
            <div className="forgetSection">
              <label className="mb-3" htmlFor="password">
                {t("Password")}
              </label>
              <input
                className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <p className="forgetLink">
              {checkout_url_param ?
                <NavLink to={`/forgetpassword?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`}>
                {t("ForgetPw")}
              </NavLink>
                :
                <NavLink to={`/forgetpassword?store=${store}&lang=${selectedLanguage}`}>
                {t("ForgetPw")}
              </NavLink>
              }
              </p>
            </div>
            {error && <div className="text-red">{error}</div>}

            {loading && (
              <div className="loading">
                <div className="loadingWrapper">
                  <div id="loading"> </div>
                  <span className="loadMessage">{t("loader")}</span>
                </div>
              </div>
            )}

            <div className="full">
              <button
                className="bg-black hover:bg-white/2 text-white font-normal py-3 px-10 rounded"
                type="submit"
              >
                {t("login")}
              </button>
              <p className="text-right regitestLink">
              {checkout_url_param ?
                <NavLink to={`/register?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`}>
                {t("Register")}
              </NavLink> :
              <NavLink to={`/register?store=${store}&lang=${selectedLanguage}`}>
                {t("Register")}
              </NavLink>
              }
              </p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginForm;
