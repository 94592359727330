import React from "react";
import sacoorBrothersLogo from '../../utils/sacoorbrothers_logo_regular.png'
import sacoorBrothersBlueLogo from '../../utils/sacoor-blue.svg'
import sacoorBrothersOneLogo from '../../utils/sacoorone.svg'
import LanguageChanger from "../LanguageChanger/LanguageChanger";
import { useTranslation } from 'react-i18next';

const Header = ({selectedLanguage, handleLanguageChange}:{selectedLanguage:any, handleLanguageChange:any}) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const store = queryParams.get('store');

  const getStoreLogo = (storeDomain: string | null) => {
    if (storeDomain && storeDomain.includes('sacoorone')) {
      return sacoorBrothersOneLogo;
    } else if (storeDomain && storeDomain.includes('sacoorblue')) {
      return sacoorBrothersBlueLogo;
    } else {
      return sacoorBrothersLogo;
    }
  };

  return (
    <div className="header">
    <div className="full text-center">
      <div className="headerTop">
        {t("welcome")}
      </div>
      <LanguageChanger />
       <h2 className="mb-5 mt-3 text-3xl text-center logo">
          <a href={`https://${store}`}>
            <img src={getStoreLogo(store)} alt="Sacoor" />
          </a>
      </h2>
     </div>
     </div>
  );
};

export default Header;
