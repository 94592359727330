import React, { useEffect, useState } from "react";
import { forgetPassword } from "../../api/api";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation();

  const getInitialLanguage = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("lang") || "en";
  };

  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(getInitialLanguage);

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  const [formData, setFormData] = useState({
    email: "",
    domain: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const store = queryParams.get("store");
  const checkout_url_param = queryParams.get("checkout_url");

  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!store) {
      window.location.href = "https://www.sacoorbrothers.com";
    }
  }, [store]);
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const response = await forgetPassword(
      {
        ...formData,
        domain: store,
      },
      selectedLanguage
    );

    if (response.meta.statusCode !== 200 && response.meta.statusCode !== 201) {
      setLoading(false);
      setError(response.meta.message);
      console.log("Status code", response.meta.statusCode);
    } else {
      setSuccessMessage(response.meta.message);
      setLoading(false);
      setTimeout(() => {
        localStorage.setItem("email", formData.email);
        checkout_url_param
          ? navigate(
              `/resetpassword?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`
            )
          : navigate(`/resetpassword?store=${store}&lang=${selectedLanguage}`);
      }, 3000);
    }
  };

  return (
    <>
      <Header
        selectedLanguage={selectedLanguage}
        handleLanguageChange={handleLanguageChange}
      />
      <div className="content">
        <div className="loginForm">
          <h2 className="mb-5 text-3xl text-center">{t("ForgetPassword")}</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label className="mb-3" htmlFor="email">
                {t("email")}
              </label>
              <input
                className="appearance-none border w-full py-3 mb-5 px-3 white leading-tight focus:gray focus:shadow-outline"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            {error && <div className="text-red">{error}</div>}
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {loading && (
              <div className="loading">
                <div className="loadingWrapper">
                  <div id="loading"> </div>
                  <span className="loadMessage">{t("loader")}</span>
                </div>
              </div>
            )}
            <div className="full">
              <button
                disabled={!!successMessage}
                className={`bg-black hover:bg-white/2 text-white font-normal mt-5 py-3 px-10 rounded ${
                  successMessage ? "cursor-not-allowed opacity-50" : ""
                }`}
                type="submit"
              >
                {t("ForgetPassword")}
              </button>
            </div>
            <div className="linkRight">
              {checkout_url_param ? (
                <NavLink
                  to={`/?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`}
                  className=""
                >
                  {t("login")}
                </NavLink>
              ) : (
                <NavLink
                  to={`/?store=${store}&lang=${selectedLanguage}`}
                  className=""
                >
                  {t("login")}
                </NavLink>
              )}

              {checkout_url_param ? (
                <NavLink
                  to={`/register?store=${store}&lang=${selectedLanguage}&checkout_url=${checkout_url_param}`}
                >
                  {t("Register")}
                </NavLink>
              ) : (
                <NavLink
                  to={`/register?store=${store}&lang=${selectedLanguage}`}
                  className=""
                >
                  {t("Register")}
                </NavLink>
              )}
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgetPassword;
