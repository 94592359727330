const API_URL = process.env.REACT_APP_BACKEND_URL;

const queryParams = new URLSearchParams(window.location.search);
const store = queryParams.get('store');
const checkout_url_param = queryParams.get('checkout_url');

export const login = async (email: string, password: string, checkout_url: string, language:string) => {
    try {
      const body:any = {
        email, password
      }

      if(checkout_url_param){
        body.checkout_url = checkout_url_param
      }

      const response = await fetch(
        `${API_URL}/auth/sign-in?domain=${store}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept-language': language 
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error('Login failed');
      }
      return response.json();
    } catch (error) {
      throw new Error('Login failed');
    }
  };

export const register = async (formData: any, language: any) => {
    try {
      const response = await fetch(
        `${API_URL}/auth/sign-up?domain=${store}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "accept-language" : language
        },
        body: JSON.stringify(formData),
      });
      return response.json();
    } catch (error) {
      throw new Error('Registration failed');
    }
  };

  export const verificationResponse = async (formData: any, language: any) => {
    try {
      const response = await fetch(
        `${API_URL}/auth/verify-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "accept-language" : language
        },
        body: JSON.stringify(formData),
      });
      return response.json();
    } catch (error) {
      throw new Error('Registration failed');
    }
  };

  export const resendResponse = async (formData: any, language: any) => {
    try {
      const response = await fetch(
        `${API_URL}/auth/resend-confirmation-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "accept-language" : language
        },
        body: JSON.stringify(formData),
      });
      return response.json();
    } catch (error) {
      throw new Error('Registration failed');
    }
  };


  export const forgetPassword = async (formData: any, language: any) => {
    try {
      const response = await fetch(
        `${API_URL}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "accept-language" : language
        },
        body: JSON.stringify(formData),
      });
      return response.json();
    } catch (error) {
      throw new Error('Registration failed');
    }
  };


  export const resetPassword = async (formData: any, language: any) => {
    try {
      const response = await fetch(
        `${API_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "accept-language" : language
        },
        body: JSON.stringify(formData),
      });
      return response.json();
    } catch (error) {
      throw new Error('Registration failed');
    }
  };

  export const RefreshToken = async (userUUID: string, checkout_url: string, language: any) => {
     try {

      const body:any = {
        userUUID: localStorage.getItem('cognitoUserId')
      }

      if(checkout_url_param){
        body.checkout_url = checkout_url_param
      }

      const refreshTokenData = await fetch(
        `${API_URL}/auth/refresh-token-signin?domain=${store}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "accept-language" : language,
          'Authorization': `Bearer ${localStorage.getItem('RefreshToken')}`,
        },
        body: JSON.stringify(body)
      });
  
      if (!refreshTokenData.ok) {
        throw new Error("refreshToken");
      }

      return refreshTokenData.json();
      
    } catch (error) {
      if (error instanceof Response) {
        const errorResponse = await error.json();
        const errorMessage = `${errorResponse.meta.status}: ${errorResponse.meta.message}`;
        throw new Error(errorMessage);
      } else {
        if (language === 'pt') {
          throw new Error("Login com usuário válido");
        } else if(language === 'ar') {
          throw new Error("تسجيل الدخول باستخدام مستخدم صالح");
        } 
        else {
          throw new Error("Login with valid user");
        }
      }
    }
  };