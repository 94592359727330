import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageChanger: React.FC = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    updateLanguageInURL(language);
    window.location.reload(); 
  };

  const updateLanguageInURL = (language: string) => {
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('lang', language);
    window.history.pushState({}, '', currentUrl.toString());
  };

  return (
    <div className='languageSelector'>
      <select value={i18n.language} onChange={(e) => handleChangeLanguage(e.target.value)}>
        <option value="en">{t("Eng")}</option>
        <option value="pt">{t("PT")}</option>
        <option value="ar">{t("Arabic")}</option>
      </select>
    </div>
  );
};

export default LanguageChanger;
